import * as React from 'react'
import { Box, Chip, CircularProgress, FormControlLabel, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popover, Switch, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography, } from '@mui/material'
import { MoreVertOutlined, } from '@mui/icons-material'
import EnhancedTableHead from '../components/EnhancedTableHead'
import EnhancedTableToolbar from '../components/EnhancedTableToolbar'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { getComparator, stableSort, StyledTableCell, StyledTableRow } from '../utils/constants'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function DataGrid({
    t,
    rows,
    isLoading,
    popoverItems,
    headCells,
    setSelected,
    label,
    searchTerm,
    handleSearch,
    from,
    setBody,
    handleSwitchChange,
    isSubmitting,
}) {
    const [filterBy, setFilterBy] = React.useState(0)
    const [order, setOrder] = React.useState('desc')
    const [orderBy, setOrderBy] = React.useState('createAt')
    const [page, setPage] = React.useState(0)
    const [dense, setDense] = React.useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClickPopOver = (event, dataSelected, index) => {
        setAnchorEl(event.currentTarget)
        var newSelected = []
        newSelected.push({ ...dataSelected, index: index })
        setSelected(newSelected)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'car-update' : undefined

    const handleExport = (file) => {
        if (!isLoading) {
            if (rows.length >= 1) {
                if (file === "excel") {
                    const worksheet = XLSX.utils.json_to_sheet(rows)

                    const workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Cars")

                    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })

                    const blob = new Blob([excelBuffer], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    })

                    saveAs(blob, "cars.xlsx")
                }
                if (file === "pdf") {
                    const header = headCells.map((header) => header.label)

                    const docDefinition = {
                        content: [
                            { text: 'Data', style: 'header' },
                            {
                                table: {
                                    headerRows: 1,
                                    widths: new Array(header.length).fill('auto'),
                                    body: [
                                        header,
                                        ...rows.map(row => headCells.map(key => row[key.id]))
                                    ],
                                    layout: {
                                        defaultBorder: false,
                                        fillColor: function (i, node) { return (i % 2 === 0) ? '#f0f0f0' : null; }
                                    }
                                }
                            }
                        ],
                        styles: {
                            header: {
                                fontSize: 18,
                                bold: true,
                                margin: [0, 0, 0, 10]
                            }
                        }
                    }

                    pdfMake.createPdf(docDefinition).download('cars.pdf')
                }
            }
        }
    }

    const handleClickFilterBy = (event, value) => {
        setFilterBy(value)
    }

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { width: '200px' }
                }}
            >
                <MenuList
                    disablePadding
                    sx={{
                        '& > *': {
                            '&:first-of-type': {
                                borderTopColor: 'divider',
                                borderTopStyle: 'solid',
                                borderTopWidth: '1px'
                            },
                            padding: '12px 16px'
                        }
                    }}
                >
                    {popoverItems.map((item, index) => (
                        <MenuItem
                            key={item.id}
                            onClick={() => {
                                item.onClick()
                                handleClose()
                            }}
                        >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                {t(item.label)}
                            </Typography>
                        </MenuItem>
                    ))}
                </MenuList>
            </Popover>

            <Box
                sx={{
                    width: '100%',
                    px: 3,
                    py: 2,
                }}
            >
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <EnhancedTableToolbar
                        handleSearch={handleSearch}
                        handleExport={handleExport}
                        filterBy={filterBy}
                        handleClickFilterBy={handleClickFilterBy}
                        label={label}
                        setBody={setBody}
                    />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .filter(item =>
                                        Object.values(item).some(value =>
                                            value
                                                .toString()
                                                .toLowerCase()
                                                .includes(searchTerm.toLowerCase())
                                        )
                                    )
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const totalPrice = from === "renting" ? row.cars.reduce((acc, item) => {
                                            if (item.selling_price) {
                                                return acc + item.selling_price
                                            }
                                            return acc
                                        }, 0) : 0

                                        return (
                                            <StyledTableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                {headCells.map((column) => {
                                                    if (column.id === 'cars') {
                                                        return (
                                                            <StyledTableCell key={column.id}>
                                                                {row[column.id].map((renting) => (
                                                                    <div key={renting.id}>
                                                                        <div>▶ {renting.car_model} ({renting.car_type}) {renting.selling_price} TZS</div>
                                                                    </div>
                                                                ))}
                                                            </StyledTableCell>
                                                        );
                                                    } else {
                                                        return (
                                                            <StyledTableCell key={column.id}>
                                                                {row[column.id]}
                                                            </StyledTableCell>
                                                        )
                                                    }
                                                })}
                                                <StyledTableCell>
                                                    <IconButton
                                                        onClick={(event) => {
                                                            handleClickPopOver(event, row, index)
                                                        }}
                                                    >
                                                        <MoreVertOutlined />
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {from !== "users" &&
                                                        <Chip
                                                            label={t(
                                                                from === "renting" && row.paid_amount >= totalPrice ?
                                                                    "paid" :
                                                                    from === "renting" && row.paid_amount > 0 ?
                                                                        "pending" :
                                                                        from === "renting" && row.paid_amount === 0 ?
                                                                            "not_paid" :
                                                                            from === "cars" && row.sold ?
                                                                                "sold_out" :
                                                                                "not_sold"
                                                            )}
                                                            color={
                                                                from === "renting" && row.paid_amount >= totalPrice ?
                                                                    "success" :
                                                                    from === "renting" && row.paid_amount > 0 ?
                                                                        "error" :
                                                                        from === "renting" && row.paid_amount === 0 ?
                                                                            "error" :
                                                                            from === "cars" && row.sold ?
                                                                                "success" :
                                                                                "error"
                                                            }
                                                            variant="outlined"
                                                            sx={{ width: 100, }}
                                                        />
                                                    }
                                                    {from === "users" &&
                                                        <Switch
                                                            checked={row.active}
                                                            onChange={() => {
                                                                handleSwitchChange(row, index)
                                                            }}
                                                            disabled={isSubmitting}
                                                        />
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: (dense ? 33 : 53) * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isLoading === true &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <CircularProgress
                                sx={{
                                    mx: 'auto',
                                    my: 3,
                                }}
                            />
                        </Box>
                    }
                    {rows.length === 0 && isLoading === false &&
                        <Typography
                            sx={{ my: 3, }}
                            align='center'
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {t('no_items')}
                        </Typography>
                    }
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={t('rows_per_page')}
                    />
                </Paper>
                <FormControlLabel
                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                    label={t('dense_padding')}
                />
            </Box>
        </>
    )
}

export default DataGrid