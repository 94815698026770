import React from 'react'
import { Box, Container, Grid, Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { CarRentalOutlined, CarRepairOutlined, DirectionsCarOutlined, PaidOutlined } from '@mui/icons-material'
import { DashBoardCard, GraphSummary, InvoicesNotPaidCard } from '../components'
import { useTranslation } from 'react-i18next'
import { postAPIRequest } from '../services/postAPIRequest'
import { getAllCarUrl, getRentingsPaidUrl } from '../utils/urls'
import carsModel from '../models/cars'
import dayjs from 'dayjs'
import rentingsModel from '../models/rentings'
import { AuthContext } from '../contexts/AuthContext'

function Home() {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)
    const [cars, setCars] = React.useState([])
    const [rentings, setRentings] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        postAPIRequest(
            getAllCarUrl,
            {
                "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            setCars,
            carsModel,
            setIsLoading
        )
    }, [currentUser])

    React.useEffect(() => {
        postAPIRequest(
            getRentingsPaidUrl,
            {
                "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
                "to": dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')
            },
            setRentings,
            rentingsModel,
            setIsLoading
        )
    }, [])

    const items = [
        {
            title: t('total_cars'),
            subtitle: cars.length,
            body: t('all_time'),
            icon: (<DirectionsCarOutlined color='default' />),
            href: '/cars',
        },
        {
            title: t('have_been_sold'),
            subtitle: cars.filter(item =>
                Object.values(item).some(value =>
                    value
                        .toString()
                        .toLowerCase()
                        .includes("")
                ) && item.paid === true
            ).length,
            body: t('all_time'),
            icon: (<CarRentalOutlined color='default' />),
            href: '/orders',
        },
        {
            title: t('remain'),
            subtitle: cars.filter(item =>
                Object.values(item).some(value =>
                    value
                        .toString()
                        .toLowerCase()
                        .includes("")
                ) && item.paid === false
            ).length,
            body: t('all_time'),
            icon: (<CarRepairOutlined color='default' />),
            href: '/cars',
        },
        {
            title: t('paid'),
            subtitle: rentings.filter(item =>
                Object.values(item).some(value =>
                    value
                        .toString()
                        .toLowerCase()
                        .includes("")
                )
            ).length,
            body: t('all_time'),
            icon: (<PaidOutlined color='default' />),
            href: '/orders',
        },
    ]

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth={false}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {items.map((item) => (
                            <Grid
                                key={uuid()}
                                item
                                lg={3}
                                sm={6}
                                xl={3}
                                xs={12}
                                sx={{
                                    '&:hover': {
                                        color: "red",
                                    },
                                }}
                            >
                                <Link
                                    to={item.href}
                                    replace={true}
                                >
                                    {isLoading === false &&
                                        <DashBoardCard
                                            title={item.title}
                                            subtitle={item.subtitle}
                                            body={item.body}
                                            icon={item.icon}
                                        />
                                    }
                                    {isLoading === true &&
                                        <Skeleton variant='rectangular' height="17vh" />
                                    }
                                </Link>
                            </Grid>
                        ))}
                        <Grid
                            item
                            lg={8}
                            md={12}
                            xl={8}
                            xs={12}
                        >
                            <GraphSummary />
                        </Grid>
                        <Grid
                            item
                            lg={4}
                            md={12}
                            xl={4}
                            xs={12}
                        >
                            <InvoicesNotPaidCard />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Home