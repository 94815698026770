import { MoneyOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { paymentFields } from '../utils/formFields'
import { paymentValues } from '../utils/values'
import axios from 'axios'
import { addPaymentUrl } from '../utils/urls'
import { AuthContext } from '../contexts/AuthContext'

function Invoice({
  selected,
  setSelected,
  handleRefresh,
}) {
  const { t } = useTranslation()
  const { currentUser, } = React.useContext(AuthContext)
  const totalPrice = selected[0]?.cars.reduce((acc, item) => {
    if (item.selling_price) {
      return acc + item.selling_price
    }
    return acc
  }, 0)
  const typographyLeft = [
    { name: "G.Solution Motors", variant: "subtitle1", textAlign: "left", padding: 0, },
    { name: "Barabara no. 08", variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("EMAIL")}: beforwarddodoma2018@gmail.com`, variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("WEBSITE")}: www.beforward.jp`, variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("TEL")}: +255 652737879 / 652909405`, variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("bill_to")}:`, variant: "body2", textAlign: "left", padding: 3, },
    { name: `${selected[0]?.client_name}.`, variant: "subtitle1", textAlign: "left", padding: 0, },
  ]

  const typographyRight = [
    { name: `${t("DATE")}:  ${dayjs().format("DD/MM/YYYY")}`, variant: "body2", textAlign: "right", padding: 6, },
    { name: `${t("PAYMENT_TERMS")}: ${t("BANK")}/${t("CASH")}`, variant: "body2", textAlign: "right", padding: 1, },
    { name: `${t("DUE_DATE")}: ${dayjs().format("DD/MM/YYYY")}`, variant: "body2", textAlign: "right", padding: 1, },
    { name: `${t("TIN")}: 163-011-999`, variant: "body2", textAlign: "right", padding: 1, },
    { name: `${t("BALANCE_DUE")}: ${totalPrice}`, variant: "subtitle1", textAlign: "right", padding: 1, },
  ]

  const header = [
    { name: `${t("item")}`, align: "left", },
    { name: `${t("quantity")}`, align: "right", },
    { name: `${t("rate")}`, align: "right", },
    { name: `${t("price")}`, align: "right", },
  ]

  const body = [
    {
      name: `${t("cars")}`,
      quantity: selected[0]?.cars.length,
      rate: selected[0]?.cars.reduce((acc, item) => {
        if (item.selling_price) {
          return acc + item.selling_price
        }
        return acc
      }, 0),
      amount: totalPrice,
    },
  ]

  const footer = [
    { name: `${t("total")}: TZS ${totalPrice}`, variant: "subtitle2", textAlign: "right", padding: 2, },
    { name: `${t("notes")}:`, variant: "subtitle2", textAlign: "left", padding: 6, },
    { name: `${t("make_all_cheques_payable")}`, variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("if_you_have_any_question")}`, variant: "body2", textAlign: "left", padding: 0, },
    { name: `${t("most_welcome")}`, variant: "body2", textAlign: "left", padding: 0, },
  ]

  const paymentSchema = Yup.object().shape(
    paymentFields.reduce((obj, field) => {
      obj[field.name] = Yup.string().required(`${t(field.label)} ${t("is_required")}`)
      return obj
    }, {})
  )

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Typography
          variant='h5'
        >
          G.SOLUTION MOTORS
        </Typography>
        <Typography
          variant='h5'
        >
          {t("invoices")}
        </Typography>
      </Box>
      <Typography
        variant='body1'
        textAlign="right"
      >
        #CRS00{selected[0]?.id}
      </Typography>
      <Grid container sx={{ mx: 2, }}>
        <Grid item xs={5.8}>
          {typographyLeft.map((item, index) => (
            <Typography
              key={index}
              textAlign={item.textAlign}
              variant={item.variant}
              sx={{ mt: item.padding }}
            >
              {item.name}
            </Typography>
          ))}
        </Grid>
        <Grid
          item
          xs={5.8}
        >
          {typographyRight.map((item, index) => (
            <Typography
              key={index}
              textAlign={item.textAlign}
              variant={item.variant}
              sx={{ mt: item.padding }}
            >
              {item.name}
            </Typography>
          ))}
        </Grid>
      </Grid>
      <Box sx={{ m: 2, }}>
        <Typography
          variant='subtitle2'
        >
          {t("BANK_ACCOUNTS")}:{t("BANK_NAME")} - BFD AGENT LIMITED
        </Typography>
        <Typography
          variant='subtitle2'
        >
          {t("ACCOUNTS_NUMBER")} - NMB Tshs.53110008421, CRDB Tshs.015C674068700 Usd.025C674068700
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: 'black'
            }}
          >
            <TableRow>
              {header.map((head, index) => (
                <TableCell key={index} align={head.align}>
                  <Typography
                    color="white"
                  >
                    {t(head.name)}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map((item, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.rate}</TableCell>
                <TableCell align="right">TZS {item.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {footer.map((item, index) => (
        <Typography
          key={index}
          textAlign={item.textAlign}
          variant={item.variant}
          sx={{ mt: item.padding }}
        >
          {item.name}
        </Typography>
      ))}
      {selected[0]?.paid_amount < totalPrice &&
        <Formik
          initialValues={{ ...paymentValues[0] }}
          validationSchema={paymentSchema}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
            setSubmitting(true)
            try {
              const response = await axios.post(
                addPaymentUrl,
                {
                  "renting_id": selected[0]?.id,
                  "user_id": currentUser?.id,
                  "amount": values.amount,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                    'car-renting-token-auth': currentUser?.token,
                  },
                }
              )
              if (response.data.error === false) {
                const newarray = [
                  ...selected.slice(0, 0),
                  {
                    ...selected[0],
                    paid_amount: selected[0]?.paid_amount + values.amount
                  },
                  ...selected.slice(0 + 1)
                ]
                resetForm()
                handleRefresh()
                setSelected(newarray)
                setSubmitting(false)
              }
            } catch (error) {
              setErrors(error.response.data)
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting, values, touched, errors, handleChange, handleBlur, }) => (
            <Form
              id="payment"
              noValidate
              autoComplete="off"
            >
              <Typography variant="h5" sx={{ mt: 4 }}>Amount Paid : {selected[0]?.paid_amount}</Typography>
              <Grid
                container
                sx={{
                  mt: 4,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {paymentFields.map((field) => (
                  <Grid
                    item
                    key={field.name}
                  >
                    <TextField
                      color='secondary'
                      name={field.name}
                      type={field.type}
                      label={t(field.label)}
                      fullWidth
                      value={values[field.name]}
                      error={Boolean(errors[field.name] && touched[field.name])}
                      helperText={touched[field.name] && errors[field.name]}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                ))}
                <Grid
                  item
                  sx={{ pl: 2, my: "auto" }}
                >
                  <Box>
                    <Button
                      startIcon={<MoneyOutlined />}
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{
                        '&:hover': {
                          backgroundColor: "primary.main",
                        },
                      }}
                    >
                      Pay
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>}
      <Box sx={{ mt: "31vh" }} />
    </>
  )
}

export default Invoice