import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Autocomplete, Avatar, Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import * as Yup from "yup"
import { useTranslation } from 'react-i18next'
import { CarRentalOutlined, CloseOutlined } from '@mui/icons-material'
import { addCarUrl, createCarRentingUrl, searchCarsUrl, updateCarUrl } from '../utils/urls'
import axios from 'axios'
import uuid from 'react-uuid'
import { AuthContext } from '../contexts/AuthContext'
import { fuels, transmissions, wheelDrives } from '../utils/constants'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function FormDialog({
    openFormDialog,
    handleCloseFormDialog,
    action,
    steps,
    fields,
    values,
    selected,
    from,
    handleRefresh,
    setSeverity,
    setSeverityMessage,
    handleClickAlert,
}) {
    const { t } = useTranslation()
    const { currentUser, } = React.useContext(AuthContext)

    const schema = Yup.object().shape(
        fields.reduce((obj, field) => {
            if (field.type === 'email') {
                obj[field.name] = Yup.string()
                    .email(`${t(field.label)} ${t("must_be_valid_email")}`)
                    .required(`${t(field.label)} ${t("is_required")}`)
            } else if (field.type === 'number') {
                obj[field.name] = Yup.number()
                    .typeError(`${t(field.label)} ${t("must_be_a_number")}`)
                    .min(1, `${t(field.label)} ${t("must_be_a_greater_than_or_equal_to_1")}`)
                    .required(`${t(field.label)} ${t("is_required")}`)
            } else if (field.name === 'organization_tin') {
                obj[field.name] = Yup.string()
                    .required(`${t(field.label)} ${t("is_required")}`)
                    .optional()
            } else {
                obj[field.name] = Yup.string().required(`${t(field.label)} ${t("is_required")}`)
            }
            return obj
        }, {})
    )

    const [activeStep, setActiveStep] = React.useState(0)
    const [carOptions, setCarOptions] = React.useState([])
    const inputRef = React.useRef(null)
    const [carValue, setCarValue] = React.useState("")
    const [carData, setCarData] = React.useState([])
    const [error, setError] = React.useState("")
    const [skipped, setSkipped] = React.useState(new Set())

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = (values, setErrors) => {
        const errors = {}
        let hasErrors = false

        // Check if all required fields have been filled out
        if (activeStep === 0) {
            fields.slice(0, from === "renting" ? 3 : 5).forEach((field) => {
                if (!values[field.name]) {
                    errors[field.name] = `${t(field.label)} ${t("is_required")}`
                    hasErrors = true
                } else if (field.type === 'email' && !/\S+@\S+\.\S+/.test(values[field.name])) {
                    errors[field.name] = `${t(field.label)} ${t("must_be_valid_email")}`
                    hasErrors = true
                }
            })
        }
        if (activeStep === 1) {
            if (from !== "renting") {
                fields.slice(5, 8).forEach((field) => {
                    if (!values[field.name]) {
                        errors[field.name] = `${field.label} is required`
                        hasErrors = true
                    }
                })
            }
        }
        if (activeStep === 2) {
            fields.slice(from === "renting" ? 5 : 8).forEach((field) => {
                if (!values[field.name]) {
                    errors[field.name] = `${field.label} is required`
                    hasErrors = true
                }
            })
        }

        // If there are errors, display them and prevent proceeding to the next step
        if (hasErrors) {
            setErrors(errors)
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
            setErrors({})
        }
        // console.log(errors)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStep)
            return newSkipped
        })
    }

    const handleReset = () => {
        setCarOptions([])
        setCarData([])
        setActiveStep(0)
    }

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [])

    const fetchCarOptions = async (searchValue) => {
        try {
            const response = await axios.post(
                searchCarsUrl,
                { "query": searchValue },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                        'car-renting-token-auth': currentUser?.token,
                    },
                }
            )
            if (response.data.error === false) {
                setCarOptions(response.data.data)
            }
        } catch (error) {
            setCarOptions([])
        }
    }

    return (
        <Dialog
            open={openFormDialog}
            TransitionComponent={Transition}
            onClose={handleCloseFormDialog}
            fullWidth
            maxWidth="sm"
        >
            <Formik
                enableReinitialize
                initialValues={{ ...values[0] }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
                    setSubmitting(true)
                    try {
                        if (from === "renting") {
                            const carBody = []
                            for (let i = 0; i < carData.length; i++) {
                                carBody.push({
                                    car_id: carData[i].id,
                                    selling_price: carData[i].selling_price,
                                })
                            }
                            const response = await axios.post(
                                createCarRentingUrl,
                                {
                                    ...values,
                                    "cars": carBody,
                                    "user_id": currentUser?.id
                                },
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                                        'car-renting-token-auth': currentUser?.token,
                                    },
                                }
                            )
                            if (response.data.error === false) {
                                handleReset()
                                handleRefresh()
                                resetForm()
                                setSubmitting(false)
                            }
                        } else {
                            const requestBody = action === "create" ?
                                { ...values, "user_id": currentUser?.id } :
                                { ...values, "id": selected.length === 1 && selected[0]?.id, "user_id": currentUser?.id }
                            const response = await axios.post(
                                action === "create" ? addCarUrl : updateCarUrl,
                                requestBody,
                                {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
                                        'car-renting-token-auth': currentUser?.token,
                                    },
                                }
                            )
                            if (response.data.error === false) {
                                if (action === "edit") {
                                    handleCloseFormDialog()
                                    setSeverityMessage(response.data.message)
                                    setSeverity("success")
                                    handleClickAlert()
                                }
                                handleReset()
                                handleRefresh()
                                resetForm()
                                setSubmitting(false)
                            }
                        }
                    } catch (error) {
                        setErrors(error.response.data)
                        error.response.data.message && setError(error.response.data.message[0])
                        setSubmitting(false)
                        console.log("Error", error)
                    }
                }}
            >
                {({ isSubmitting, values, touched, errors, handleChange, handleBlur, setErrors, setFieldValue }) => (
                    <Form
                        noValidate
                        autoComplete="off"
                    >
                        <DialogTitle>
                            {action === "create" && t("add_car")}
                            {action === "rent" && t("car_marketing")}
                            {action === "view" &&
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseFormDialog}
                                >
                                    <CloseOutlined />
                                </IconButton>
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{ width: '100%' }}>
                                {action !== "view" &&
                                    <Stepper activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps = {}
                                            const labelProps = {}
                                            if (isStepOptional(index) && from === "renting") {
                                                labelProps.optional = (
                                                    <Typography variant="caption">Optional</Typography>
                                                )
                                            }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{t(label)}</StepLabel>
                                                </Step>
                                            )
                                        })}
                                    </Stepper>
                                }
                                {activeStep === steps.length || action === "view" ? (
                                    <React.Fragment>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    {fields.map((field) => (
                                                        <TableRow
                                                            key={field.label}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {t(field.label)}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {field.type === "date" ? values[field.name].format('dddd, MMMM D, YYYY HH:mm:ss.SSS') : values[field.name]}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {carData.map((car, index) => (
                                                        <TableRow
                                                            key={uuid()}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {car.car_model}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {car.selling_price}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {error !== "" &&
                                            <Typography
                                                sx={{
                                                    color: "red",
                                                }}
                                            >
                                                {error}
                                            </Typography>
                                        }
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {activeStep === 0 ? (
                                            <React.Fragment>
                                                {fields.slice(0, from === "renting" ? 3 : 5).map((field) => (
                                                    <React.Fragment key={field.name}>
                                                        {field.name === "wheel_drive" ?
                                                            <TextField
                                                                id="role"
                                                                color='secondary'
                                                                select
                                                                margin='normal'
                                                                label={t(field.label)}
                                                                value={values[field.name]}
                                                                onChange={(event) => {
                                                                    setFieldValue('wheel_drive', event.target.value)
                                                                }}
                                                                fullWidth
                                                            >
                                                                {wheelDrives.map((wheelDrive) => (
                                                                    <MenuItem
                                                                        key={uuid()}
                                                                        value={wheelDrive.value}
                                                                    >
                                                                        {wheelDrive.value}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField> : <TextField
                                                                color='secondary'
                                                                name={field.name}
                                                                type={field.type}
                                                                label={t(field.label)}
                                                                margin="normal"
                                                                fullWidth
                                                                value={values[field.name]}
                                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                                helperText={touched[field.name] && errors[field.name]}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            />
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </React.Fragment>
                                        ) : activeStep === 1 ? (
                                            <React.Fragment>
                                                {fields.slice(from === "renting" ? 3 : 5, from === "renting" ? 4 : 8).map((field) => (
                                                    <Box key={field.name}>
                                                        {from === "car" || field.name === "organization_tin" ?
                                                            <TextField
                                                                color='secondary'
                                                                name={field.name}
                                                                type={field.type}
                                                                label={t(field.label)}
                                                                margin="normal"
                                                                fullWidth
                                                                value={values[field.name]}
                                                                error={Boolean(errors[field.name] && touched[field.name])}
                                                                helperText={touched[field.name] && errors[field.name]}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                            /> : null}
                                                    </Box>
                                                ))}
                                            </React.Fragment>
                                        ) : activeStep === 2 ? (
                                            <React.Fragment>
                                                {from === "renting" &&
                                                    <>
                                                        <Autocomplete
                                                            multiple
                                                            options={carOptions}
                                                            getOptionLabel={(option) => option.id.toString()}
                                                            filterOptions={(x) => x}
                                                            noOptionsText={t("no_car")}
                                                            includeInputInList
                                                            filterSelectedOptions
                                                            onChange={(event, value) => {
                                                                let price = 0
                                                                const data = []
                                                                for (let i = 0; i < value.length; i++) {
                                                                    price = price + value[i].price
                                                                }
                                                                setFieldValue("car_price", price)
                                                                for (let i = 0; i < value.length; i++) {
                                                                    data.push({ ...value[i], hour: 1 })
                                                                }
                                                                setCarData(data)
                                                            }}
                                                            renderOption={(props, option) => {

                                                                return (
                                                                    <li {...props}>
                                                                        <List sx={{ width: "100%" }}>
                                                                            <ListItem
                                                                                secondaryAction={
                                                                                    <Typography edge="end">
                                                                                        {option.selling_price}
                                                                                    </Typography>
                                                                                }
                                                                            >
                                                                                <ListItemAvatar>
                                                                                    <Avatar>
                                                                                        <CarRentalOutlined />
                                                                                    </Avatar>
                                                                                </ListItemAvatar>
                                                                                <ListItemText
                                                                                    primary={`${option.car_model}(${option.car_type})-${option.year}`}
                                                                                    secondary={`
                                                                                    ▶ ${option.color} ▶ ${option.fuel} ▶ ${option.transmission}
                                                                                    `}
                                                                                />
                                                                            </ListItem>
                                                                        </List>
                                                                    </li>
                                                                )
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={t("search_car")}
                                                                    color='secondary'
                                                                    fullWidth
                                                                    margin='normal'
                                                                    variant="outlined"
                                                                    value={carValue}
                                                                    onChange={(event) => {
                                                                        setCarValue(event.target.value)
                                                                        fetchCarOptions(event.target.value)
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </>
                                                }
                                                {fields.slice(from === "renting" ? 4 : 8).map((field, index) => {
                                                    const mySelect = field.label === "fuel" ?
                                                        fuels :
                                                        transmissions

                                                    return (
                                                        <React.Fragment key={field.name}>
                                                            {field.label === "fuel" || field.label === "transmission" ?
                                                                <TextField
                                                                    id="role"
                                                                    color='secondary'
                                                                    select
                                                                    margin='normal'
                                                                    label={t(field.label)}
                                                                    value={values[field.name]}
                                                                    onChange={(event) => {
                                                                        field.label === "fuel" && setFieldValue('fuel', event.target.value)
                                                                        field.label === "transmission" && setFieldValue('transmission', event.target.value)
                                                                    }}
                                                                    fullWidth
                                                                >
                                                                    {mySelect.map((fuel) => (
                                                                        <MenuItem
                                                                            key={uuid()}
                                                                            value={fuel.value}
                                                                        >
                                                                            {fuel.value}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField> :
                                                                <TextField
                                                                    color='secondary'
                                                                    name={field.name}
                                                                    type={field.type}
                                                                    label={t(field.label)}
                                                                    margin="normal"
                                                                    fullWidth
                                                                    value={values[field.name]}
                                                                    error={Boolean(errors[field.name] && touched[field.name])}
                                                                    helperText={touched[field.name] && errors[field.name]}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                />
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })}
                                                {from === "renting" && carData.map((car, index) => (
                                                    <TextField
                                                        color='secondary'
                                                        name={car.car_model}
                                                        type="number"
                                                        label={car.car_model}
                                                        margin="normal"
                                                        fullWidth
                                                        value={car.selling_price}
                                                        onChange={(event) => {
                                                            const newArray = [
                                                                ...carData.slice(0, index),
                                                                {
                                                                    ...carData[index],
                                                                    price: event.target.value
                                                                },
                                                                ...carData.slice(index + 1)
                                                            ]
                                                            setCarData(newArray)
                                                        }}
                                                    />
                                                ))}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    All steps completed - you&apos;re finished
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Button
                                                color="inherit"
                                                disabled={activeStep === 0}
                                                onClick={handleBack}
                                                sx={{ mr: 1 }}
                                            >
                                                {t('back')}
                                            </Button>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {isStepOptional(activeStep) && from === "renting" && (
                                                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                    Skip
                                                </Button>
                                            )}

                                            <Button
                                                onClick={() => {
                                                    handleNext(values, setErrors)
                                                }}
                                            >
                                                {activeStep === steps.length - 1 ? t('finish') : t('next')}
                                            </Button>
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                        </DialogContent>
                        {activeStep === steps.length && action !== "view" &&
                            <DialogActions>
                                <Button
                                    onClick={handleCloseFormDialog}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {action === "create" && t('create')}
                                    {from === "renting" && t('create')}
                                    {action === "edit" && t('edit')}
                                </Button>
                            </DialogActions>
                        }
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default FormDialog