import * as React from 'react'
import { DataGrid, FormDialog, ViewDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { carRentingSteps } from '../utils/constants'
import { carRentingFields } from '../utils/formFields'
import { carRentingHeader } from '../utils/tableHeaders'
import { getRentingsUrl } from '../utils/urls'
import { Button, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { DriveEtaOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import { postAPIRequest } from '../services/postAPIRequest'
import rentingsModel from '../models/rentings'
import dayjs from 'dayjs'

pdfMake.vfs = pdfFonts.pdfMake.vfs

function Orders() {
  const { t } = useTranslation()
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [action, setAction] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [openFormDialog, setOpenFormDialog] = React.useState(false)
  const [openViewDialog, setOpenViewDialog] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
  })

  React.useEffect(() => {
    postAPIRequest(getRentingsUrl, body, setRows, rentingsModel, setIsLoading)
  }, [body])

  const values = [
    {
      client_name: "",
      phone_number: "",
      email: "",
      organization_tin: "",
    },
  ]

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleClickOpenFormDialog = () => {
    setOpenFormDialog(true)
  }

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false)
  }

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true)
  }

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false)
  }

  const handleRefresh = () => {
    postAPIRequest(
      getRentingsUrl,
      body,
      setRows,
      rentingsModel,
      setIsLoading
    )
  }

  const carRentingPopoverItems = [
    {
      id: 'viewInvoice',
      label: 'view',
      icon: <RemoveRedEyeOutlined fontSize="small" />,
      onClick: () => {
        setAction("invoice")
        handleClickOpenViewDialog()
      },
    },
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              ml: 3,
              pt: 4,
            }}
          >
            <Button
              startIcon={<DriveEtaOutlined />}
              variant="contained"
              size="small"
              sx={{
                '&:hover': {
                  backgroundColor: "primary.main",
                },
              }}
              onClick={() => {
                setAction("rent")
                handleClickOpenFormDialog()
              }}
            >
              {t('car_marketing')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <FormDialog
        openFormDialog={openFormDialog}
        handleCloseFormDialog={handleCloseFormDialog}
        action={action}
        steps={carRentingSteps}
        fields={carRentingFields}
        values={values}
        selected={selected}
        from="renting"
        handleRefresh={handleRefresh}
      />
      <ViewDialog
        openViewDialog={openViewDialog}
        handleCloseViewDialog={handleCloseViewDialog}
        rows={rows}
        action={action}
        selected={selected}
        setSelected={setSelected}
        handleRefresh={handleRefresh}
      />
      <DataGrid
        t={t}
        rows={rows}
        isLoading={isLoading}
        popoverItems={carRentingPopoverItems}
        headCells={carRentingHeader}
        setSelected={setSelected}
        label="car_marketing"
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        from="renting"
        setBody={setBody}
      />
    </>
  )
}

export default Orders