export const carRegistrationFields = [
    { name: "car_model", type: "text", label: 'car_model' },
    { name: "chassis_no", type: "text", label: 'chassis_no' },
    { name: "car_type", type: "text", label: 'car_type' },
    { name: "engine_size", type: "text", label: 'engine_size' },
    { name: "wheel_drive", type: "text", label: 'wheel_drive' },
    { name: "purchase_price", type: "number", label: 'purchase_price' },
    { name: "selling_price", type: "number", label: 'selling_price' },
    { name: "year", type: "text", label: 'year' },
    { name: "color", type: "text", label: 'color' },
    { name: "plate_number", type: "text", label: 'plate_number' },
    { name: "fuel", type: "text", label: 'fuel' },
    { name: "transmission", type: "text", label: 'transmission' },
    { name: "vehicle_descriptions", type: "text", label: 'vehicle_description' },
]

export const carRentingFields = [
    { name: "client_name", type: "text", label: 'client_full_name' },
    { name: "phone_number", type: "text", label: 'phone_number' },
    { name: "email", type: "email", label: 'email' },
    { name: "organization_tin", type: "text", label: 'organization_tin' },
]

export const changePasswordFields = [
    { name: "old_password", type: "password", label: 'current_password' },
    { name: "new_password", type: "password", label: 'new_password' },
    { name: "confirm_new_password", type: "password", label: 'confirm_password' },
]

export const registerUserFields = [
    { name: "full_name", type: "text", label: 'full_name' },
    { name: "email", type: "email", label: 'email' },
    { name: "phone_no", type: "text", label: 'phone_number' },
    { name: "password", type: "text", label: 'password' },
]

export const paymentFields = [
    { name: "amount", type: "number", label: 'amount' },
]