import React from "react"

export const AuthContext = React.createContext()

export const AuthContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = React.useState(() => {
        const userString = localStorage.getItem('carRentingWebUser')
        const storedUser = JSON.parse(userString)
        return storedUser ?? null
    })

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                setCurrentUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}