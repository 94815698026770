import * as React from 'react'
import { DataGrid, DeleteDialog, FormDialog, } from '../components'
import { useTranslation } from 'react-i18next'
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { carRegistrationSteps } from '../utils/constants'
import { carRegistrationFields } from '../utils/formFields'
import { carRegistrationHeader } from '../utils/tableHeaders'
import { deleteCarUrl, getAllCarUrl } from '../utils/urls'
import { Button, Grid, Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { Box } from '@mui/system'
import { DeleteOutline, DriveEtaOutlined, EditOutlined, RemoveRedEyeOutlined } from '@mui/icons-material'
import { postAPIRequest } from '../services/postAPIRequest'
import carsModel from '../models/cars'
import dayjs from 'dayjs'
import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';

pdfMake.vfs = pdfFonts.pdfMake.vfs
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function Cars() {
  const { t } = useTranslation()
  const { currentUser, } = React.useContext(AuthContext)
  const [rows, setRows] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [action, setAction] = React.useState("")
  const [selected, setSelected] = React.useState([])
  const [openFormDialog, setOpenFormDialog] = React.useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [severity, setSeverity] = React.useState("")
  const [severityMessage, setSeverityMessage] = React.useState("")
  const [history, setHistory] = React.useState([])
  const [body, setBody] = React.useState({
    "from": dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss.SSS'),
    "to": dayjs().add(5, "minute").format('YYYY-MM-DD HH:mm:ss.SSS'),
  })

  React.useEffect(() => {
    postAPIRequest(getAllCarUrl, body, setRows, carsModel, setIsLoading)
  }, [body])

  const values = [
    {
      car_model: action === "view" || action === "edit" ? selected[0]?.car_model : "",
      chassis_no: action === "view" || action === "edit" ? selected[0]?.chassis_no : "",
      car_type: action === "view" || action === "edit" ? selected[0]?.car_type : "",
      engine_size: action === "view" || action === "edit" ? selected[0]?.engine_size : "",
      wheel_drive: action === "view" || action === "edit" ? selected[0]?.wheel_drive : "4W",
      purchase_price: action === "view" || action === "edit" ? selected[0]?.purchase_price : "",
      selling_price: action === "view" || action === "edit" ? selected[0]?.selling_price : "",
      year: action === "view" || action === "edit" ? selected[0]?.year : "",
      color: action === "view" || action === "edit" ? selected[0]?.color : "",
      plate_number: action === "view" || action === "edit" ? selected[0]?.plate_number : "",
      fuel: action === "view" || action === "edit" ? selected[0]?.fuel : "DIESEL",
      transmission: action === "view" || action === "edit" ? selected[0]?.transmission : "AUTOMATIC",
      vehicle_descriptions: action === "view" || action === "edit" ? selected[0]?.vehicle_descriptions : "",
    },
  ]

  const handleSearch = event => {
    setSearchTerm(event.target.value)
  }

  const handleClickOpenFormDialog = () => {
    setOpenFormDialog(true)
  }

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false)
  }

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    handleRefresh()
    setOpenDeleteDialog(false)
  }

  const handleRefresh = () => {
    postAPIRequest(getAllCarUrl, body, setRows, carsModel, setIsLoading)
  }

  const handleClickAlert = () => {
    setOpenAlert(true)
  }

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const handleUndo = async () => {
    setIsSubmitting(true)
    try {
      const response = await axios.post(
        deleteCarUrl,
        { "car_id": history[0]?.id, "user_id": currentUser?.id },
        {
          headers: {
            'Content-Type': 'application/json',
            'car-renting-sign-auth': `${process.env.REACT_APP_KEY}`,
            'car-renting-token-auth': currentUser?.token,
          },
        }
      )
      if (response.data.error === false) {
        handleRefresh()
        setIsSubmitting(false)
      }
    } catch (error) {
      setSeverityMessage(error.response.data.message[0])
      setSeverity("error")
      handleClickAlert()
      setIsSubmitting(false)
    }
  }

  const carRegistrationPopoverItems = [
    {
      id: 'viewCar',
      label: 'view',
      icon: <RemoveRedEyeOutlined fontSize="small" />,
      onClick: () => {
        setAction("view")
        handleClickOpenFormDialog()
      },
    },
    {
      id: 'editCar',
      label: 'edit',
      icon: <EditOutlined fontSize="small" />,
      onClick: () => {
        setAction("edit")
        handleClickOpenFormDialog()
      },
    },
    {
      id: 'deleteCar',
      label: 'delete',
      icon: <DeleteOutline fontSize="small" />,
      onClick: () => {
        setAction("delete")
        handleClickOpenDeleteDialog()
      },
    },
  ]

  return (
    <>
      {action === "edit" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={severity}
            sx={{
              width: '100%'
            }}
          >
            {severityMessage}
          </Alert>
        </Snackbar>
      }
      {action === "delete" &&
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openAlert}
          autoHideDuration={6000}
          message={severityMessage}
          onClose={handleCloseAlert}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                handleUndo()
                handleCloseAlert()
              }}
            >
              Undo
            </Button>
          }
        />
      }
      <Grid container>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              ml: 3,
              pt: 4,
            }}
          >
            <Button
              startIcon={<DriveEtaOutlined />}
              variant="contained"
              size="small"
              sx={{
                '&:hover': {
                  backgroundColor: "primary.main",
                },
              }}
              onClick={() => {
                setAction("create")
                handleClickOpenFormDialog()
              }}
            >
              {t('add_car')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <FormDialog
        openFormDialog={openFormDialog}
        handleCloseFormDialog={handleCloseFormDialog}
        action={action}
        steps={carRegistrationSteps}
        fields={carRegistrationFields}
        values={values}
        selected={selected}
        from="car"
        handleRefresh={handleRefresh}
        setSeverity={setSeverity}
        setSeverityMessage={setSeverityMessage}
        handleClickAlert={handleClickAlert}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        url={deleteCarUrl}
        body={selected.length === 1 && { "car_id": selected[0]?.id, "user_id": currentUser?.id }}
        selected={selected}
        setSeverity={setSeverity}
        setSeverityMessage={setSeverityMessage}
        handleClickAlert={handleClickAlert}
        history={history}
        setHistory={setHistory}
      />
      <DataGrid
        t={t}
        rows={rows}
        isLoading={isLoading}
        popoverItems={carRegistrationPopoverItems}
        headCells={carRegistrationHeader}
        setSelected={setSelected}
        label="registered_cars"
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        from="cars"
        setBody={setBody}
        isSubmitting={isSubmitting}
      />
    </>
  )
}

export default Cars