import { tableCellClasses } from '@mui/material/TableCell'
import { styled, TableCell, TableRow } from "@mui/material"

export const APP_NAME = "G.Solution Motors"

export const ADMIN = "ADIMINISTRATOR"

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) {
            return order
        }
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export const carRegistrationSteps = ['car_details', 'price_and_year', 'additional_information']

export const carRentingSteps = ['user_information', 'additional_information', 'car_information']

export const fuels = [
    {
        value: 'DIESEL',
    },
    {
        value: 'PETROL',
    },
]

export const transmissions = [
    {
        value: 'AUTOMATIC',
    },
    {
        value: 'MANUAL',
    },
]

export const wheelDrives = [
    {
        value: '4W',
    },
    {
        value: '2W',
    },
    {
        value: 'AWD',
    },
]

export const genders = [
    {
        value: 'MALE',
    },
    {
        value: 'FEMALE',
    },
]

export const roles = [
    {
        value: 'ADIMINISTRATOR',
    },
    {
        value: 'SECRETARY',
    },
]