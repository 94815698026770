// export const baseUrl = "http://127.0.0.1:4300/api/v1"
export const baseUrl = "https://cardealer.api.majorcorecode.co.tz/api/v1"
export const getAllUserUrl = `${baseUrl}/all/user`
export const getAllCarUrl = `${baseUrl}/get/cars`
export const getRentingsUrl = `${baseUrl}/get/car/rentings`
export const getRentingsPaidUrl = `${baseUrl}/get/car/rentings/paid`
export const getRentingsUnpaidUrl = `${baseUrl}/get/car/rentings/unpaid`
export const getRentingsPendingUrl = `${baseUrl}/get/car/rentings/pending`
export const changeRentingStatusUrl = `${baseUrl}/update/renting/status`
export const changeUserActiveStatusUrl = `${baseUrl}/change/active/status`
export const addPaymentUrl = `${baseUrl}/add/payment`
export const changeCarRentedStatusUrl = `${baseUrl}/update/car/rented/status`
export const addCarUrl = `${baseUrl}/create/car`
export const updateCarUrl = `${baseUrl}/update/car`
export const deleteCarUrl = `${baseUrl}/delete/car`
export const registerUserUrl = `${baseUrl}/register/user`
export const searchCarsUrl = `${baseUrl}/search/cars`
export const loginUrl = `${baseUrl}/login/user`
export const changePasswordUrl = `${baseUrl}/change/password`
export const createCarRentingUrl = `${baseUrl}/create/car/renting`
export const getTaskForSpecificUserUrl = `${baseUrl}/get/tasks/for/specific/user`
export const addOrRemoveTaskForSpecificUserUrl = `${baseUrl}/add/remove/task/for/specific/user`